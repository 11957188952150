import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import * as Sentry from '@sentry/browser';
import { from } from 'rxjs';
import { ActionPopover } from 'src/app/popovers';
import { FooterComponent, HeaderComponent, KeyboardComponent, KeyboardService } from 'src/app/shared/components';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ControlPrint1Component } from 'src/app/shared/components/control/print1/control-print1.component';
import { DictNumber, EventObject, Notification, VerificationType } from 'src/app/shared/models';
import { BluetoothDeviceType } from 'src/app/shared/models/bluetooth-device.model';
import { AppService, CheckVersionService, LocalSettingsService, NotificationService, PluginService, PluginType, ScannerService } from 'src/app/shared/services';
import { GeolocationService, TranslateService } from 'src/app/shared/services/app';
import { LogUtils } from 'src/app/shared/utils';
import satoApi from 'webaep-api';
import { ScreenControlContainerComponent } from './components/screen';
import { ScreenBaseComponent } from './components/screen/base/screen-base.component';
import { RuntimeLayoutSnapshot } from 'src/app/shared/models/memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayout } from 'src/app/shared/models/memorypack/RuntimeLayout';
import { RuntimeLayoutScreen } from 'src/app/shared/models/memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';
import { LayoutEventService } from 'src/app/shared/services/protocol/layout-event.service';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { RuntimeLayoutControlCode } from 'src/app/shared/models/runtime-layout/runtime-layout-control-code.enum';
import { HybridDataSyncronizationState } from 'src/app/shared/models/layout-state.model';
import { RequireOnlineHybridStateResult } from 'src/app/shared/models/manager/require-online-hybrid-state-result.model';
import { SynchronizeHybridDataStateResult } from 'src/app/shared/models/manager/synchronize-hybrid-data-state-result.model';

export enum ClientSetting {
  HasBluetoothTemperature = 'HasBluetoothTemperature',
  HasCamera = 'HasCamera',
  HasPrinter = 'HasPrinter',
  HasRfidPrinter = 'HasRfidPrinter',
  PrinterDpi = 'PrinterDpi',
}


@Component({
  selector: 'lc-main-page',
  templateUrl: 'main.page.html',
  styleUrls: ['./main.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPage extends BaseComponent implements OnInit {

  readonly FULL1 = 'Full1';
  readonly DUAL1 = 'Dual1';

  @ViewChild(FooterComponent, { static: true }) footerComponent: FooterComponent;
  @ViewChild(HeaderComponent, { static: true }) headerComponent: HeaderComponent;
  @ViewChild('screenComponent', { static: false }) screenComponent: ScreenBaseComponent;
  @ViewChild(KeyboardComponent, { static: true }) keyboardComponent: KeyboardComponent;

  private clientSettingValues: any;
  previousTriggerEventTick: number;
  layoutSnapshot: RuntimeLayoutSnapshot;
  layout: RuntimeLayout;
  layoutScreen: RuntimeLayoutScreen;
  layoutControl: RuntimeLayoutControl;

  bufferSendTimeout: any;
  showScannerDrawer: boolean;
  inactivityTriggerPreviousTick: number;
  inactivityTriggerTimeout: any;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private checkVersionService: CheckVersionService,
    private geolocationService: GeolocationService,
    private keyboardService: KeyboardService,
    private layoutEventService: LayoutEventService,
    private localSettingsService: LocalSettingsService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private pluginService: PluginService,
    private router: Router,
    private scannerService: ScannerService,
    private translateService: TranslateService,
  ) {
    super();

    this.subscriptions.push(
      this.router.events
      .subscribe((e: any) => {
        if (e instanceof NavigationEnd && e.url.indexOf('/main') >= 0) {
          this.refresh();
        }
      }),
      this.appService.listenToBackButtonClick()
      .subscribe(() => {
        this.platformBackButtonAction();
      })
    );
  }

  ngOnInit() {
    this.checkVersionService.check();

    this.refresh();
  }

  refresh() {
    if (!this.appService.isInitialized) {
      setTimeout(() => {
        this.refresh();
      }, 100);
      return;
    }

    const cipherLabPlugin = this.pluginService.getInstance(PluginType.CipherLab);
    const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
    const opticonPlugin = this.pluginService.getInstance(PluginType.Opticon);
    const pointMobilePlugin = this.pluginService.getInstance(PluginType.PointMobile);
    const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);
    this.showScannerDrawer = !cipherLabPlugin.isPluginAllowed()
    && !honeywellPlugin.isPluginAllowed()
    && !opticonPlugin.isPluginAllowed()
    && !pointMobilePlugin.isPluginAllowed()
    && !zebraPlugin.isPluginAllowed();

    this.keyboardService.init(this.platform, this.keyboardComponent, 'lc-main-page main');

    this.clearBufferSendTimeout();

    this.layoutSnapshot = this.appService.getLayoutSnapshot();

    let newLayout: RuntimeLayout;
    let newLayoutScreen: RuntimeLayoutScreen;
    let newLayoutControl: RuntimeLayoutControl & { forceShow?: boolean };
    if (this.layoutSnapshot?.runtimeLayout?.layoutScreens) {
      newLayout = this.layoutSnapshot.runtimeLayout;
      newLayoutScreen = newLayout.layoutScreens.get(newLayout.activeScreen);
      newLayoutControl = newLayoutScreen?.controls?.get(newLayoutScreen.primaryLayoutControlObjectId);

      // Special controls with no UI (or UI only on certain conditions...)
      if (newLayoutControl?.layoutControlCode === RuntimeLayoutControlCode.RequestClientSetting1) {
        this.handleRequestClientSetting(newLayoutControl);
        return;
      } else if (newLayoutControl?.layoutControlCode === RuntimeLayoutControlCode.RequireOnlineHybridState && !newLayoutControl?.forceShow) {
        this.handleRequireOnlineHybridState(newLayoutControl);
        return;
      } else if (newLayoutControl?.layoutControlCode === RuntimeLayoutControlCode.SynchronizeHybridDataState && !newLayoutControl?.forceShow) {
        this.handleSynchronizeHybridDataState(newLayoutControl);
        return;
      }

      if (
        (this.layoutScreen && (!newLayoutScreen || this.layoutScreen.objectId !== newLayoutScreen.objectId))
        || (this.layoutControl && (!newLayoutControl || this.layoutControl.objectId !== newLayoutControl.objectId))
      ) {
        if (this.layoutControl.layoutControlCode === RuntimeLayoutControlCode.RfidScan) this.scannerService.clear();
        if (this.inactivityTriggerTimeout) clearTimeout(this.inactivityTriggerTimeout);

        this.layout = undefined;
        this.layoutScreen = undefined;
        this.layoutControl = undefined;

        if (this.keyboardService.isVisible() && !RuntimeLayoutUtils.parseRV(newLayoutControl, 'KeyboardAutoEnable')) {
          this.keyboardService.hide();
        }

        this.clearInactivityTimeout();
        this.cdr.markForCheck();
      }
    }

    setTimeout(() => {
      if (!this.layoutSnapshot) return;

      if (this.layout && this.layoutScreen && this.layoutControl) {
        // LogUtils.warn('Refreshing the same control / scannerPlugins!');
        this.appService.refreshScannerPlugins();
        setTimeout(() => {
          this.screenComponent?.controlComponents?.forEach((cc: ScreenControlContainerComponent) => {
            if (cc.controlComponent?.refresh) cc.controlComponent.refresh();
          });
        }, 10);
      }

      this.layout = newLayout;
      this.layoutScreen = newLayoutScreen;
      this.layoutControl = newLayoutControl;

      if (this.layout && this.layoutScreen && this.layoutControl) {
        this.notificationService.consumeLocalEvents(this.layoutScreen, this.layout.runtimeSolutionStartedTick);
        this.checkInactivityTriggers();
      } else {
        LogUtils.error('Received an invalid / empty LayoutSnapshot!', this.layoutSnapshot);

        Sentry.setExtra('layoutSnapshot', this.layoutSnapshot);
        Sentry.setExtra('log', LogUtils.getLogArray());
        Sentry.captureException(new Error('Received an invalid / empty LayoutSnapshot!'));
      }

      this.cdr.markForCheck();

      setTimeout(() => {
        // sometimes the topleft menu doesn't refresh, so force it...
        this.headerComponent.refresh();
      }, 10);
    }, 10);
  }

  private checkInactivityTriggers() {
    if (!this.layoutControl?.triggers?.length) return this.clearInactivityTimeout();

    if (!this.inactivityTriggerTimeout || !this.inactivityTriggerPreviousTick) this.inactivityTriggerPreviousTick = Date.now();
    this.inactivityTriggerTimeout = setTimeout(() => {
      if (!this.inactivityTriggerTimeout) return;

      for (const trigger of this.layoutControl?.triggers || []) {
        if (Date.now() - this.inactivityTriggerPreviousTick >= trigger.inactivityInMilliSeconds) {
          this.clearInactivityTimeout();
          this.onTriggerEvent({
            platformObjectGuidId: trigger.triggerGuidId,
            platformObjectType: RuntimeLayoutEventPlatformObjectType.Trigger
          });
          return;
        };
      }

      this.checkInactivityTriggers();
    }, 250);
  }

  private clearInactivityTimeout(): void {
    if (!this.inactivityTriggerTimeout) return;

    clearTimeout(this.inactivityTriggerTimeout);
    this.inactivityTriggerTimeout = undefined;

    this.inactivityTriggerPreviousTick = undefined;
  }

  private clearBufferSendTimeout() {
    if (!this.bufferSendTimeout) return;

    clearTimeout(this.bufferSendTimeout);
    this.bufferSendTimeout = undefined;
  }

  platformBackButtonAction() {
    if (!this.layoutSnapshot) {
      navigator['app'].exitApp(); // Ionic 4
      return;
    }

    if (this.keyboardService.isVisible()) {
      this.keyboardService.hide();
      this.cdr.markForCheck();
    } else if (this.layoutScreen?.backButton) {
      this.triggerEvent(this.layoutControl, null, { platformObjectType: RuntimeLayoutEventPlatformObjectType.BackButton });
    }
  }

  onTriggerEvent(eo: EventObject, explicitButtonClick?: boolean) {
    if (!this.layoutSnapshot) return;
    if (!this.layoutControl) return;

    if (
      eo.platformObjectType !== RuntimeLayoutEventPlatformObjectType.BackButton &&
      RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGpsLock', false) &&
      !this.geolocationService.getLastKnownPosition()
    ) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('GPS lock required to continue'),
        type: RuntimeLayoutNotifyType.VerificationAlert,
      }));
      return;
    }

    this.clearBufferSendTimeout();

    const buffering = this.layoutControl.layoutControlCode === RuntimeLayoutControlCode.RfidScan
    || RuntimeLayoutUtils.parseRV(this.layoutControl, 'InputBuffering');

    if (eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.Action) {
      this.showActionMenu();
    } else if (
      eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.ForwardButton
      && this.screenComponent?.forwardButtonOverride()
    ) {
      return;
    } else if (
      eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.ForwardButton
      || (eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.Scanner && buffering) // advanceScan
      || (eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.None && buffering) // advanceScan / RFID
    ) {
      let controlsContext = this.screenComponent?.getControlsContext();
      if (buffering) {
        const bufferValues = RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'RfidBufferTids') || RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'BufferValues') || [];
        if (explicitButtonClick || bufferValues.length) {
          // start by disabling the back and action buttons
          this.layoutScreen.backButton = false;
          this.layoutScreen.actionButton = false;
          this.footerComponent.refresh();

          // if the buffer is full, send to server
          const bufferingItemCount = RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingItemCount') || RuntimeLayoutUtils.parseRV(this.layoutControl, 'InputBufferingItemCount') || 0;
          if (explicitButtonClick || bufferValues.length >= bufferingItemCount) {
            this.triggerEvent(this.layoutControl, controlsContext, eo);
          } else {
            // if a SendTimeout is set, (re)initialize it
            const sendTimeoutInSec = RuntimeLayoutUtils.parseRV(this.layoutControl, 'BufferingSendTimeoutSeconds') || RuntimeLayoutUtils.parseRV(this.layoutControl, 'InputBufferingSendTimeoutSeconds');
            if (sendTimeoutInSec) {
              this.bufferSendTimeout = setTimeout(() => {
                if (!this.bufferSendTimeout) return;

                this.triggerEvent(this.layoutControl, controlsContext, eo);
              }, sendTimeoutInSec * 1000);
            }
          }
        }
      } else {
        this.runVerifications(controlsContext, eo);
      }
    } else if (
      eo.platformObjectType === RuntimeLayoutEventPlatformObjectType.BackButton
      && this.screenComponent?.backButtonOverride()
    ) {
      return;
    } else {
      let controlsContext = this.screenComponent?.getControlsContext();
      this.triggerEvent(this.layoutControl, controlsContext, eo);
    }

    this.cdr.markForCheck();
  }

  private runVerifications(controlsContext: Map<bigint, RuntimeLayoutEventContext | null>, eo: EventObject) {
    if (
      !this.layoutControl?.objectId ||
      (Object.keys(controlsContext).length > 0 && !controlsContext?.get(this.layoutControl.objectId))
    ) {
      LogUtils.error('RunVerifications on an invalid control/controlContext!', this.layoutControl, controlsContext);

      Sentry.setExtra('layoutControl', this.layoutControl);
      Sentry.setExtra('controlsContext', controlsContext);
      Sentry.setExtra('log', LogUtils.getLogArray());

      Sentry.captureException(new Error('RunVerifications on an invalid control/controlContext!'));
      return;
    }

    let verifyErrorText = undefined;
    if (
      RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationAllowEmpty') === false &&
      !RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox') &&
      RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox') !== '0'
    ) {
      verifyErrorText = RuntimeLayoutUtils.parseRV(
        this.layoutControl,
        'VerificationText',
        this.translateService.instant('Please enter a value!')
      );
    } else if (
      RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationType') === VerificationType.Equal &&
      (RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationValue') || '').split('|').indexOf(RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox')) < 0
    ) {
      verifyErrorText = RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationText') || `${this.translateService.instant('Value must be')} '${RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationValue')}'`;
    } else if (
      RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationType') === VerificationType.Regex &&
      !(new RegExp(RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationValue'))).test(RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox'))
    ) {
      verifyErrorText = RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationText') || `${this.translateService.instant('Value must follow the format')}: '${RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationValue')}'`;
    } else if (
      RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationType') === VerificationType.LowerOrEqual &&
      (
        !this.isNumeric(RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox')) ||
        parseFloat(RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox')) < 0 ||
        parseFloat(RuntimeLayoutUtils.parseRV(controlsContext.get(this.layoutControl.objectId), 'TextBox')) > parseFloat(RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationValue', 0))
      )
    ) {
      verifyErrorText = RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationText') || `${this.translateService.instant('Value must be a number between 0 and')} ${RuntimeLayoutUtils.parseRV(this.layoutControl, 'VerificationValue', 0)}`;
    }

    if (verifyErrorText !== undefined) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Verification'),
        text: verifyErrorText,
        type: RuntimeLayoutNotifyType.VerificationAlert,
      }));
    } else {
      this.triggerEvent(this.layoutControl, controlsContext, eo);
    }
  }

  private isNumeric(str: any) {
    return !isNaN(parseFloat(str)) && isFinite(str);
  }

  private triggerEvent(layoutControl: RuntimeLayoutControl, controlsContext: Map<bigint, RuntimeLayoutEventContext | null>, eo: EventObject) {
    // if we get 2 triggers too close to one another...ignore the second one.
    if (this.previousTriggerEventTick && Date.now() - this.previousTriggerEventTick < 250) {
      LogUtils.warn('triggerEvent was called twice in less than 250ms...second attempt was ignored.', controlsContext, eo);
      return;
    }
    this.previousTriggerEventTick = Date.now();

    this.screenComponent?.controlComponents?.forEach((cc: ScreenControlContainerComponent) => {
      cc.controlComponent?.unsubscribeScannerSubscription();
    });

    const controlContext = controlsContext?.get(layoutControl.objectId);
    if (RuntimeLayoutUtils.parseLayoutValue(controlContext?.values?.get('BufferValues'))) {
      controlContext.values.get('BufferValues').valueJson = JSON.stringify(RuntimeLayoutUtils.parseRV(controlsContext.get(layoutControl.objectId), 'BufferValues', []).join('|'));
    }
    if (RuntimeLayoutUtils.parseLayoutValue(controlContext?.values?.get('RfidBufferTids'))) {
      controlContext.values.get('RfidBufferTids').valueJson = JSON.stringify(RuntimeLayoutUtils.parseRV(controlsContext.get(layoutControl.objectId), 'RfidBufferTids', []).join('|'));
    }

    this.layoutEventService.trigger(
      this.layoutSnapshot,
      controlsContext,
      eo.eventContext,
      eo.platformObjectType,
      eo.platformObjectGuidId,
    ).subscribe((result: boolean) => {
      if (eo.callback) eo.callback(result);
    }, (error: any) => {
      LogUtils.error('triggerEvent.trigger() error:', error);
    });
  }

  private showActionMenu() {
    from(this.popoverCtrl.create({
      component: ActionPopover,
      componentProps: { layoutControl: this.layoutControl },
      cssClass: `popover-action`,
      backdropDismiss: true,
      showBackdrop: true
    }))
    .subscribe((popover: HTMLIonPopoverElement) => {
      from(popover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<string>) => {
        const actionGuidId = result.data;
        if (!actionGuidId) return;

        this.screenComponent.preActionTrigger()
        .subscribe(() => {
          this.layoutEventService.trigger(
            this.layoutSnapshot,
            null,
            null,
            RuntimeLayoutEventPlatformObjectType.Action,
            actionGuidId
          ).subscribe((result: boolean) => {

          }, (error: any) => {
            LogUtils.error('showActionMenu.trigger() error:', error);
          });

          this.cdr.markForCheck();
        });
      });
      popover.present();
    });
  }

  @HostListener('document:click', ['$event'])
  anywhereClick(event: MouseEvent) {
    this.appService.updateSolutionInfoToast(null);
  }

  private handleRequestClientSetting(layoutControl: RuntimeLayoutControl): void {
    if (!layoutControl) return;

    let hasUnknowns = false;
    let hasNoValues = false;
    this.clientSettingValues = {};
    for (const rvKey of Object.keys(layoutControl.renderValues || {})) {
      if (rvKey.indexOf('ClientSetting.') < 0) continue;

      const clientSettingGuidId = rvKey.split('.')[1];
      const clientSetting = RuntimeLayoutUtils.parseRV(layoutControl, rvKey);
      switch (clientSetting) {
        case ClientSetting.HasPrinter:
          const btPrinterDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato]);
          this.clientSettingValues[clientSettingGuidId] = satoApi.isPrinter() || btPrinterDevices?.length > 0;
          hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          break;
        case ClientSetting.HasBluetoothTemperature:
          const btTempDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Thermometer]);
          this.clientSettingValues[clientSettingGuidId] = btTempDevices?.length > 0;
          hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          break;
        case ClientSetting.HasCamera:
          if (this.platform.is('android') || this.platform.is('ios')) {
            this.clientSettingValues[clientSettingGuidId] = true; // should have a proper check...
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
            break;
          }
          if (satoApi.isPrinter() || !navigator?.mediaDevices?.getUserMedia) {
            this.clientSettingValues[clientSettingGuidId] = false;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
            break
          }

          navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            this.clientSettingValues[clientSettingGuidId] = stream.getVideoTracks().length > 0;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          })
          .catch((error) => {
            this.clientSettingValues[clientSettingGuidId] = false;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          });
          break;
        case ClientSetting.HasRfidPrinter:
          const rfidPrinterDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.PrinterSato]);
          this.clientSettingValues[clientSettingGuidId] = rfidPrinterDevices?.length > 0 && rfidPrinterDevices.some(d => d.settings.rfid);
          hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          break;
        case ClientSetting.PrinterDpi:
          if (satoApi.isPrinter()) {
            const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
            satoPlugin.status()
            .subscribe((status: any) => {
              this.clientSettingValues[clientSettingGuidId] = status?.satoPrinterVariables?.HeadInfo?.dpi;
              hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
            });
          } else if (ControlPrint1Component.defaultPrinter) {
            this.clientSettingValues[clientSettingGuidId] = ControlPrint1Component.defaultPrinter.settings?.dpi;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          } else {
            const printerDpiDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato]);
            this.clientSettingValues[clientSettingGuidId] = printerDpiDevices?.[0]?.settings?.dpi || 0;
            hasNoValues = hasNoValues || !this.clientSettingValues[clientSettingGuidId];
          }
          break;
        default:
          this.clientSettingValues[clientSettingGuidId] = null;
          hasUnknowns = true;
          break;
      }
    }

    setTimeout(() => { // give it some time as some settings are not synchronous
      this.triggerUilessEvent(layoutControl, hasUnknowns ? 'Unknown' : hasNoValues ? 'NoValue' : 'Value');
    }, 100);
  }

  private handleRequireOnlineHybridState(layoutControl: RuntimeLayoutControl & { forceShow?: boolean }): void {
    if (!layoutControl) return;

    this.clientSettingValues = undefined;

    const mobileEngineLayoutState = this.appService.getMobileEngineLayoutState();

    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'online') {
      this.triggerUilessEvent(layoutControl, 'NotHybrid');
      return;
    } else if (mobileEngineLayoutState?.online) {
      this.triggerUilessEvent(layoutControl, 'Online');
      return;
    }

    layoutControl.forceShow = true;
    this.refresh();
  }

  private handleSynchronizeHybridDataState(layoutControl: RuntimeLayoutControl & { forceShow?: boolean }): void {
    if (!layoutControl) return;

    this.clientSettingValues = undefined;

    const mobileEngineLayoutState = this.appService.getMobileEngineLayoutState();

    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'online') {
      this.triggerUilessEvent(layoutControl, 'NotHybrid');
      return;
    } else if (mobileEngineLayoutState?.queueCount === 0 && mobileEngineLayoutState?.hybridDataSyncronizationState === HybridDataSyncronizationState.Ready) {
      this.triggerUilessEvent(layoutControl, 'Synchronized');
      return;
    }

    layoutControl.forceShow = true;
    this.refresh();

    from(windowAny.mobileEngine.instance.invokeMethodAsync('HybridDeviceControlSynchronizeHybridDataStateAsync', mobileEngineLayoutState?.hybridDataSyncronizationState || HybridDataSyncronizationState.Ready, 3))
    .subscribe((result: SynchronizeHybridDataStateResult) => {
      if (!result) return;

      LogUtils.log(result.debugText);
      if (result.success) {
        this.triggerUilessEvent(layoutControl, result.resultPort);
      } else if (result.timeout) {
        this.triggerUilessEvent(layoutControl, 'Timeout');
      } else {
        this.triggerUilessEvent(layoutControl, 'Error');
      }
    });
  }

  private triggerUilessEvent(layoutControl: RuntimeLayoutControl, portName: string): void {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    const controlContextValues = new Map<string, RuntimeLayoutValue | null>();
    for (const guidId of Object.keys(this.clientSettingValues || {})) {
      controlContextValues.set('ClientValue.' + guidId, Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(this.clientSettingValues[guidId]),
        valueTypeId: RuntimeLayoutValueType.String,
      }));
    }

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      controlContextValues.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }
    const controlsContext = new Map<bigint, RuntimeLayoutEventContext | null>();
    controlsContext.set(
      layoutControl.objectId,
      Object.assign(new RuntimeLayoutEventContext(), {
        values: controlContextValues
      })
    );

    this.triggerEvent(
      layoutControl,
      controlsContext,
      {
        eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
        platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
      }
    );
  }

}
