import { Injectable, Injector } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';
import satoApi from 'webaep-api'
import { Scan } from 'src/app/shared/models';
import { map } from 'rxjs/operators';

export interface SatoPluginSettings {
}

@Injectable({
  providedIn: 'root'
})
export class SatoPlugin extends BasePlugin {

  private isScannerEnabled: boolean;
  private isPluginAllowedChecked: boolean;
  private lastScanValue: any;
  private lastScanTick: number;
  private satoPrinterVariables: any;
  private scannerDataCallback: any = undefined;
  private settings: SatoPluginSettings;

  constructor(
    injector: Injector,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'SatoPlugin';

    this.settings = {};
  }

  isPluginAllowed(): boolean {
    return satoApi.isPrinter();
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Sato device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.log('Connecting to sato printer...');
    return from(
      satoApi.connect()
    ).pipe(
      map(() => {
        satoApi.fetchVariables().then((val) => {
          this.satoPrinterVariables = val || {};
        });

        this.initializeSatoScanner();
      })
    );
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Sato device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'disable_scanner':
        // this.log('Disabling scanner...');
        this.isScannerEnabled = false;
        return of(null);
      case 'enable_scanner':
        // this.log('Enabling scanner...');
        this.isScannerEnabled = true;
        return of(null);
      case 'write':
        if (options.payloadType === 'ZPL') {
          const data = { OnlineData: options.writeData };
          this.log(`Print ${options.payloadType}: ${JSON.stringify(data)}`);
          return from(satoApi.saveVariables(data));
        } else {
          this.log(`Print ${options.payloadType}: ${options.writeData}`);
          return from(satoApi.print(options.writeData, { quantity: options.printCount }));
        }
      case 'set_user_data_callback':
        this.log('SetUserDataCallback...');
        satoApi.setUserDataCallback(options.callback);
        return of(null);
      default:
        return of(null);
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Sato device...');
      this.isPluginAllowedChecked = true;
      return of('Not running on Sato device...');
    }

    return of({
      log: Array.from(this.logRingBuffer),
      satoPrinterVariables: this.satoPrinterVariables,
    });
  }

  private handleScanData(data: Scan) {
    if (data?.value == null) return;
    if (this.lastScanTick && Date.now() - this.lastScanTick < 1 * 1000 && this.lastScanValue === data.value) return;

    this.lastScanTick = Date.now();
    this.lastScanValue = data.value;

    this.scannerService.emitScan({
      value: data.value,
      valueType: data.valueType,
      source: 'SATO Scanner',
    });
  }

  private initializeSatoScanner() {
    this.log('Initializing scanner');

    if (this.scannerDataCallback != undefined) {
      this.log('Scanner is already initialized');
      return;
    }

    this.log('Setting scanner callback');

    this.scannerDataCallback = (data: string) => {
      if (!this.isScannerEnabled) return;

      if (!data || data === '\n') return;
      this.log('Scan data received: ' + data);

      data = data.replace(new RegExp('\n', 'g'), '');
      this.handleScanData({ value: data, valueType: 'SATO' });
    };

    satoApi.setScannerCallback(this.scannerDataCallback);
  }

}
