<div *ngIf="layoutControl && layoutControl.headDesignStyleObjectId"
  class="container flex-col">
  <lc-control-visual1 #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    [layoutDesigns]="layoutDesigns"
    [layoutTexts]="layoutTexts"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-visual1>
</div>

<div *ngIf="layoutControl && !layoutControl.headDesignStyleObjectId"
  [ngSwitch]="layoutControl.layoutControlCode"
  class="container flex-col">

  <lc-control-camera1 *ngSwitchCase="CAMERA1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-camera1>

  <lc-control-checklist1 *ngSwitchCase="CHECKLIST1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-checklist1>

  <lc-control-external1 *ngSwitchCase="EXTERNAL1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-external1>

  <lc-control-input1 *ngSwitchCase="INPUT1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-input1>

  <lc-control-list1 *ngSwitchCase="LIST1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    [layoutDesigns]="layoutDesigns"
    [layoutTexts]="layoutTexts"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-list1>

  <lc-control-location1 *ngSwitchCase="LOCATION1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-location1>

  <lc-control-menu1 *ngSwitchCase="MENU1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-menu1>

  <lc-control-mainmenu1 *ngSwitchCase="PLATFORMMENU1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-mainmenu1>

  <lc-control-print1 *ngSwitchCase="PRINT1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-print1>

  <lc-control-quantity-list1 *ngSwitchCase="QUANTITYLIST1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutDesigns]="layoutDesigns"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    [layoutTexts]="layoutTexts"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-quantity-list1>

  <lc-control-request-client-setting1 *ngSwitchCase="REQUESTCLIENTSETTING1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-request-client-setting1>

  <lc-control-require-online-hybrid-state *ngSwitchCase="REQUIREONLINEHYBRIDSTATE"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-require-online-hybrid-state>

  <lc-control-rfid-locate *ngSwitchCase="RFIDLOCATE"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-rfid-locate>

  <lc-control-rfid-inventory *ngSwitchCase="RFIDINVENTORY"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutDesigns]="layoutDesigns"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    [layoutTexts]="layoutTexts"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-rfid-inventory>

  <lc-control-rfid-scan *ngSwitchCase="RFIDSCAN"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-rfid-scan>

  <lc-control-scale1 *ngSwitchCase="SCALE1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-scale1>

  <lc-control-smartimage1 *ngSwitchCase="SMARTIMAGE1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    [smartImages]="smartImages"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-smartimage1>

  <lc-control-sog1 *ngSwitchCase="SOG1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [(layoutScreen)]="layoutScreen"
    (layoutScreenChange)="layoutScreenChange.emit($event)"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-sog1>

  <lc-control-swish-qr1 *ngSwitchCase="SWISHQR1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-swish-qr1>

  <lc-control-synchronize-hybrid-data-state *ngSwitchCase="SYNCHRONIZEDHYBRIDDATASTATE"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-synchronize-hybrid-data-state>

  <lc-control-temp1 *ngSwitchCase="TEMP1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-temp1>

  <lc-control-update-esl1 *ngSwitchCase="UPDATEESL1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-update-esl1>

  <lc-control-visual1 *ngSwitchCase="VISUAL1"
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    [layoutDesigns]="layoutDesigns"
    [layoutTexts]="layoutTexts"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-visual1>

  <lc-control-update1 *ngSwitchDefault
    #controlComponent
    class="flex-1"
    [layout]="layout"
    [layoutControl]="layoutControl"
    [layoutScreen]="layoutScreen"
    (triggerEvent)="onTriggerEvent($event)">
  </lc-control-update1>

  <div *ngIf="shortActions?.length"
    class="flex-row wrap"
    style="column-gap: 2px; padding: 2px 0 2px 2px;">
    <button *ngFor="let action of shortActions || []"
      class="button"
      [style.visibility]="action.actionGuidId ? 'visible' : 'hidden'"
      [style.width]="'calc(' + (25 * (action.shortActionSize || 1)) + '% - 2px)'"
      (click)="shortActionClick(action.actionGuidId)">
      <div class="button-inner">
        <div *ngIf="action.shortActionIcon" class="flex-1"></div>

        <i *ngIf="action.shortActionIcon" class="icon {{ action.shortActionIcon }}"></i>

        <div class="flex-1"></div>

        <div class="label">
          {{ action.shortActionText || action.actionText }}
        </div>

        <div class="flex-1"></div>
      </div>
    </button>
  </div>

</div>