import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ControlBaseComponent } from 'src/app/shared/components/control/base/control-base.component';
import { DictNumber, DictString, EventObject } from 'src/app/shared/models';
import { RuntimeLayout } from 'src/app/shared/models/memorypack/RuntimeLayout';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';
import { RuntimeLayoutControlAction } from 'src/app/shared/models/memorypack/RuntimeLayoutControlAction';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutHead } from 'src/app/shared/models/memorypack/RuntimeLayoutHead';
import { RuntimeLayoutScreen } from 'src/app/shared/models/memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutSmartImage } from 'src/app/shared/models/memorypack/RuntimeLayoutSmartImage';
import { RuntimeLayoutSnapshot } from 'src/app/shared/models/memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutControlCode } from 'src/app/shared/models/runtime-layout/runtime-layout-control-code.enum';
import { VibrationService } from 'src/app/shared/services';
import { LayoutEventService } from 'src/app/shared/services/protocol/layout-event.service';
import { LogUtils } from 'src/app/shared/utils';



@Component({
  selector: 'lc-screen-control-container',
  templateUrl: 'screen-control-container.component.html',
  styleUrls: ['./screen-control-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenControlContainerComponent implements OnInit {

  readonly CAMERA1 = RuntimeLayoutControlCode.CameraControl1;
  readonly CHECKLIST1 = RuntimeLayoutControlCode.ChecklistControl1;
  readonly EXTERNAL1 = RuntimeLayoutControlCode.ExternalControl1;
  readonly INPUT1 = RuntimeLayoutControlCode.InputControl1;
  readonly LIST1 = RuntimeLayoutControlCode.ListControl1;
  readonly LOCATION1 = RuntimeLayoutControlCode.LocationControl1;
  readonly MENU1 = RuntimeLayoutControlCode.MenuControl1;
  readonly PLATFORMMENU1 = RuntimeLayoutControlCode.PlatformMainMenu1;
  readonly PRINT1 = RuntimeLayoutControlCode.PrintControl1;
  readonly QUANTITYLIST1 = RuntimeLayoutControlCode.QuantityList1;
  readonly REQUESTCLIENTSETTING1 = RuntimeLayoutControlCode.RequestClientSetting1;
  readonly REQUIREONLINEHYBRIDSTATE = RuntimeLayoutControlCode.RequireOnlineHybridState;
  readonly RFIDINVENTORY = RuntimeLayoutControlCode.RfidInventory;
  readonly RFIDLOCATE = RuntimeLayoutControlCode.RfidLocate;
  readonly RFIDSCAN = RuntimeLayoutControlCode.RfidScan;
  readonly SMARTIMAGE1 = RuntimeLayoutControlCode.SmartImageControl1;
  readonly SOG1 = RuntimeLayoutControlCode.SogControl1;
  readonly SWISHQR1 = RuntimeLayoutControlCode.SwishQrControl1;
  readonly SYNCHRONIZEDHYBRIDDATASTATE = RuntimeLayoutControlCode.SynchronizeHybridDataState;
  readonly SCALE1 = RuntimeLayoutControlCode.ScaleControl1;
  readonly TEMP1 = RuntimeLayoutControlCode.Temperature1;
  readonly UPDATEESL1 = RuntimeLayoutControlCode.UpdateESL1;
  readonly VISUAL1 = RuntimeLayoutControlCode.VisualControl1;


  @ViewChild('controlComponent') controlComponent: ControlBaseComponent;

  @Input() layout: RuntimeLayout;
  @Input() layoutControl: (RuntimeLayoutControl & { headDesignStyleObjectId: bigint }) | RuntimeLayoutHead; // to keep the template happy
  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutScreen: RuntimeLayoutScreen;
  @Input() layoutSnapshot: RuntimeLayoutSnapshot;
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;
  @Input() smartImages: RuntimeLayoutSmartImage[];
  @Output() layoutScreenChange = new EventEmitter<RuntimeLayoutScreen>();
  @Output() triggerEvent = new EventEmitter<EventObject>();

  shortActions: any[];

  constructor(
    private layoutEventService: LayoutEventService,
    private vibrationService: VibrationService,
  ) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  private refresh() {
    if (!this.layoutControl) return;
    const layoutControl = this.layoutControl as RuntimeLayoutControl;
    if (!(layoutControl.actions || []).some(x => x.shortAction)) return;

    // populate the shortActions array (if configured)
    this.shortActions = [];
    const maxPosition = layoutControl.actions.reduce((previousValue: any, currentValue: RuntimeLayoutControlAction) => {
      previousValue = Math.max(previousValue, currentValue.shortActionPosition || 0);
      return previousValue;
    }, 0);
    for (let pos = 1; pos <= 8; pos++) {
      if (pos > maxPosition) break;

      const shortActionForPosition = layoutControl.actions.find(x => x.shortAction && (x.shortActionPosition || 1) === pos);

      if (shortActionForPosition) {
        this.shortActions.push(shortActionForPosition);
        pos += (shortActionForPosition.shortActionSize || 1) - 1;
      }
      else this.shortActions.push(new RuntimeLayoutControlAction());
    }
  }

  backButtonOverride(): boolean {
    if (!this.controlComponent) return false;

    return this.controlComponent.backButtonOverride();
  }

  forwardButtonOverride(): boolean {
    if (!this.controlComponent) return false;

    return this.controlComponent.forwardButtonOverride();
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    if (!this.controlComponent) return null;

    return this.controlComponent.getControlContext();
  }

  preActionTrigger(): Observable<void> {
    if (!this.controlComponent) return null;

    return this.controlComponent.preActionTrigger();
  }

  onTriggerEvent(eo: EventObject) {
    this.triggerEvent.emit(eo);
  }

  shortActionClick(actionGuidId: string) {
    if (!actionGuidId) return;

    this.vibrationService.vibrate();

    this.controlComponent.preActionTrigger()
    .subscribe(() => {
      this.layoutEventService.trigger(
        this.layoutSnapshot,
        null,
        null,
        RuntimeLayoutEventPlatformObjectType.Action,
        actionGuidId
      ).subscribe((result: boolean) => {

      }, (error: any) => {
        LogUtils.error('shortActionClick.trigger() error:', error);
      });
      // this.cdr.markForCheck();
    });
  }

}

